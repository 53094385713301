<template>
  <div class="d-flex align-center flex-nowrap flex-grow-1 column-ref">
    <!-- {{cache.doc}} -->
    <document-ref-icon
      :visible="showIcon"
      :icon-name="iconName"
      :mixed="mixed"
      class="mr-2"
    />
    <div class="nowrap name flex-grow-1">{{name}}</div>
  </div>
</template>

<script>

import ClientCache from '@/mixins/client-cache'

export default {
  inheritAttrs: false,
  components: {
    DocumentRefIcon: () => import('@/components/document-ref-icon')
  },
  computed: {
    canEdit () {
      return !!this.editFn && this.paramsTy === 'cond-expr'
    },
    editFn () {
      return this.columnProps?.edit
    },
    name () {
      return this.id ? this.cache?.doc?.name : this.$t('t.None')
    },
    paramsTy () {
      return this.cache?.doc?.paramsTy
    },
    iconName () {
      if (!this.paramsTy) { return }

      let r = `i.${this.toPascalCase(this.paramsTy)}`
      if (this.paramsTy.includes('aging-scenario')) {
        r = 'i.AgingScenarios'
      }
      return r
    }
  },
  data () {
    return {
      cacheType: ClientCache.CacheType.FieldRef
    }
  },
  methods: {
    handleEdit () {
      this.columnProps.edit(this.cache?.doc)
    }
  },
  mixins: [ClientCache],
  props: {
    columnProps: Object,
    id: Number,
    mixed: Boolean,
    showIcon: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="stylus" scoped>
.name
  margin-left 1px
</style>
